import React, { FunctionComponent, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import classNames from "classnames";
import Field from "../Field";
import VideoBox from "../VideoBox";
import { isDev } from "../../utils/env";
import "./GetInTouch.scss";

interface GetInTouchProps {
  title: string;
	subtitle: string;
  text: string;
	video: string;
}

const GetInTouch: FunctionComponent<GetInTouchProps> = props => {
  const { title, subtitle, text, video } = props;
	const userEmailFieldRef = useRef();
	const userMessageFieldRef = useRef();
	const recaptchaRef = useRef();
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formProcessing, setFormProcessing] = useState(false);
	const [formIsError, setFormIsError] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState(false);
	const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
	const [showRecaptcha, setShowRecaptcha] = useState(false);
	const [captchaValue, setCaptchaValue] = useState();

	const sendEmail = (e) => {
		e.preventDefault();
		setFormProcessing(true);

		const contactParams = {
			user_email: userEmailFieldRef.current.value,
			message: userMessageFieldRef.current.value,
			'g-recaptcha-response': captchaValue
		};

		emailjs.send(
			process.env.GATSBY_EMAILJS_SERVICE_ID,
			process.env.GATSBY_EMAILJS_TEMPLATE_ID,
			contactParams,
			process.env.GATSBY_EMAILJS_PUBLIC_KEY,
		)
			.then((result) => {
				if (result.status === 200) {
					setFormSubmitted(true);
					setFormProcessing(false);
				}
			}, (error) => {
				setFormSubmitError(true);
				setFormProcessing(false);
				recaptchaRef.current.reset();
			});
	};

	const handleReCaptchaChange = (value) => {
		if (value) {
			setCaptchaValue(value);
			setSubmitButtonDisable(false);
		}
	};

	const handleProcessing = () => {
		const inputFeilds = document.querySelectorAll(".get-in-touch__form-field");

		Array.from(inputFeilds).map(input => {
			if (input.value !== "") {
				setFormIsError(false);
				setFormSubmitError(false);
			} else {
				setFormIsError(true);
			}
		})
	};

	const handleFieldChange = () => {
		setShowRecaptcha(true);
	};

  return (
    <section className="get-in-touch">
			{video && (
				<div className="get-in-touch__video">
					<VideoBox video={video} className="get-in-touch__video-frame" />
				</div>
			)}
      <div className="container">
        <div className="get-in-touch__content">
					<div className="get-in-touch__head">
						{title && (
							<h2 className="get-in-touch__title">{title}</h2>
						)}
						{subtitle && (
							<div className="get-in-touch__subtitle">{subtitle}</div>
						)}
						{text && (
							<div
								className="get-in-touch__text"
								dangerouslySetInnerHTML={{
									__html: text,
								}}
							/>
						)}
					</div>
					{formSubmitted ? (
						<div className="get-in-touch__success">
							<div className="get-in-touch__success-title">
								Message was sent successfully
							</div>
						</div>
					) : (
						<form
							className={classNames(
								"get-in-touch__form",
								formProcessing && "get-in-touch__form_processing"
							)}
							onSubmit={sendEmail}
						>
							<div className="get-in-touch__form-group">
								<Field
									inputRef={userEmailFieldRef}
									onChange={handleFieldChange}
									name="user_email"
									type="email"
									placeholder="Your email"
									rootClassName="get-in-touch__form-field-wrap"
									className="get-in-touch__form-field"
									mod="simple"
									required
								/>
							</div>
							<div className="get-in-touch__form-group">
								<Field
									inputRef={userMessageFieldRef}
									onChange={handleFieldChange}
									name="message"
									type="text"
									placeholder="Tell us about your problem"
									rootClassName="get-in-touch__form-field-wrap"
									className="get-in-touch__form-field"
									mod="simple"
									required
								/>
							</div>
							{showRecaptcha && (
								<div className="get-in-touch__form-recaptcha">
									<ReCAPTCHA
										ref={recaptchaRef}
										sitekey={isDev ? process.env.GATSBY_RECAPTCHA_SITEKEY_DEV : process.env.GATSBY_RECAPTCHA_SITEKEY}
										onChange={handleReCaptchaChange}
										hl="en"
									/>
								</div>
							)}
							<div className="get-in-touch__form-footer">
								<button
									className={classNames(
										"get-in-touch__form-button",
										submitButtonDisable && "disabled"
									)}
									type="submit"
									onClick={handleProcessing}
								>
									<span className="get-in-touch__form-button-label">
										Send message
									</span>
									{formProcessing ? (
										<span className="get-in-touch__spinner" />
									) : (
										<svg
											className="get-in-touch__form-button-icon"
											width="13"
											height="13"
											viewBox="0 0 13 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.45521 5.79156L5.65571 1.99206L6.65729 0.990479L12.1667 6.49989L6.65729 12.0093L5.65571 11.0077L9.45521 7.20823H0.833374V5.79156H9.45521Z"
												fill="black"
											/>
										</svg>
									)}
								</button>
								{formIsError && (
									<div className="get-in-touch__form-error">
										Fill in all fields
									</div>
								)}
								{formSubmitError && (
									<div className="get-in-touch__form-message">
										Something went wrong. Please try again later
									</div>
								)}
							</div>
						</form>
					)}
				</div>
      </div>
    </section>
  );
};

export default GetInTouch;
