import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ButtonLink from "../ButtonLink";
import Logo from "../../assets/images/casa-logo.svg";
import "./Footer.scss";

const Footer: FunctionComponent = () => {
	const date = new Date();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
					<div className="footer__wrap">
						<Link className="footer__logo" to="/">
							<img
								className="footer__logo-image"
								src={Logo}
								alt="CASA by leviathan security group"
								width="196"
								height="36"
							/>
						</Link>
						<div className="footer__text">
							CASA has built upon the industry recognized standards coming from OWASP's Application Security Verification Standard (ASVS)
						</div>
					</div>
					<nav className="footer__nav">
						<h3 className="footer__nav-title">CASA</h3>
						<ul className="footer__nav-list">
							<li className="footer__nav-item">
								<AnchorLink
									className="footer__nav-link"
									href="#section-about-us"
									offset="100"
								>
									About CASA
								</AnchorLink>
							</li>
							<li className="footer__nav-item">
								<AnchorLink
									className="footer__nav-link"
									href="#section-how-it-works"
									offset="100"
								>
									How it works
								</AnchorLink>
							</li>
							<li className="footer__nav-item">
								<AnchorLink
									className="footer__nav-link"
									href="#section-features"
									offset="100"
								>
									OWASP ASVS
								</AnchorLink>
							</li>
							<li className="footer__nav-item">
								<AnchorLink
									className="footer__nav-link"
									href="#section-benefits"
									offset="100"
								>
									About us
								</AnchorLink>
							</li>
							<li className="footer__nav-item">
								<a
									className="footer__nav-link"
									href="/terms"
									target="_blank"
								>
									Terms of use
								</a>
							</li>
						</ul>
					</nav>
          <div className="footer__aside">
						<div className="footer__button-wrap">
							<ButtonLink
								className="footer__button"
								title="Get started with CASA"
								url="/quiz"
							/>
						</div>
						<div className="footer__copyright">
							All rights reserved &copy; LSG {date.getFullYear()}
						</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
