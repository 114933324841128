import React, { FunctionComponent } from "react";
import ButtonLink from "../ButtonLink";
import "./Intro.scss";

interface IntroProps {
	title: string;
	subtitle: string;
	text: string;
	badge: string;
	link: {
		url: string;
		label: string;
	};
}

const Intro: FunctionComponent<IntroProps> = props => {
	const {
		title,
		subtitle,
		text,
		badge,
		link
	} = props;

	return (
		<section className="intro">
			<div className="container">
				<div className="intro__content">
					<div className="intro__content-main">
						{title && (
							<h1
								className="intro__title"
								dangerouslySetInnerHTML={{
									__html: title,
								}}
							/>
						)}
						{subtitle && (
							<div className="intro__subtitle">{subtitle}</div>
						)}
						{text && (
							<div className="intro__text">{text}</div>
						)}
					</div>
					<div className="intro__content-footer">
						{link && (
							<ButtonLink
								title={link.label}
								anchor={link.url}
							/>
						)}
					</div>
					{badge && (
						<div className="intro__badge">{badge}</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default Intro;
