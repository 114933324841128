import React, { FunctionComponent } from "react";
import classNames from "classnames";
import "./VideoBox.scss";

interface VideoBoxProps {
  video: string;
  className?: string;
}

const VideoBox: FunctionComponent<VideoBoxProps> = props => {
  const { video, className } = props;

  return (
    <div className={classNames("video-box", className)}>
      {video && (
        <video
          className="video-box__frame"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={video} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default VideoBox;
