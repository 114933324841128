import React, { FunctionComponent } from "react";
import CollapsibleList from "../CollapsibleList";
import ButtonLink from "../ButtonLink";
import "./InfoBox.scss";

interface InfoBoxProps {
  id?: string;
  title: string;
  text: string;
  message: string;
  link: {
    url: string;
    label: string;
  };
  list: Array<{
    title: string;
    list?: Array<{
      text: string;
    }>;
  }>;
}

const InfoBox: FunctionComponent<InfoBoxProps> = props => {
  const { id, title, list, text, link, message } = props;

  return (
    <section className="info-box" id={id}>
      <div className="container">
        <div className="info-box__inner">
          {title && (
            <h2 className="info-box__title">{title}</h2>
          )}
          {text && (
            <div
              className="info-box__text info-box__text_mobile"
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          )}
          {message && (
            <div
              className="info-box__message"
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          )}
					<div className="info-box__body">
						<div className="info-box__content">
							{list && (
                <div className="info-box__list">
                  <CollapsibleList data={list} name="info-box" />
                </div>
              )}
						</div>
						<div className="info-box__aside">
              {text && (
                <div
                  className="info-box__text info-box__text_main"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}
							{link && (
								<div className="info-box__footer">
									<ButtonLink
										className="info-box__button"
										title={link.label}
										url={link.url}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
      </div>
    </section>
  );
};

export default InfoBox;
