import React, { FunctionComponent } from "react";
import ButtonLink from "../ButtonLink";
import "./WhyUs.scss";

interface WhyUsProps {
  id?: string;
  title: string;
	subtitle: string;
  text: string;
  link: {
    url: string;
    label: string;
  };
  list: Array<{
    title: string;
		text: string;
  }>;
}

const WhyUs: FunctionComponent<WhyUsProps> = props => {
  const { id, title, subtitle, list, text, link } = props;

  return (
    <section className="why-us" id={id}>
      <div className="container">
        <div className="why-us__inner">
          {title && (
            <h2 className="why-us__title">{title}</h2>
          )}
					{subtitle && (
            <div
							className="why-us__subtitle"
							dangerouslySetInnerHTML={{
								__html: subtitle,
							}}
						/>
          )}
					<div className="why-us__body">
						<div className="why-us__aside">
							{text && (
								<div className="why-us__text">{text}</div>
							)}
              {link && (
                <div className="why-us__footer why-us__footer_main">
                  <ButtonLink
                    className="why-us__button"
                    title={link.label}
                    url={link.url}
                  />
                </div>
              )}
						</div>
						<div className="why-us__content">
							{list && (
                <ul className="why-us__list">
                  {list.map(item => (
                    <li className="why-us__list-item" key={item.title}>
                      {item.title && (
                        <div className="why-us__list-title">{item.title}</div>
                      )}
											{item.text && (
                        <div className="why-us__list-text">{item.text}</div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {link && (
                <div className="why-us__footer why-us__footer_mobile">
                  <ButtonLink
                    className="why-us__button"
                    title={link.label}
                    url={link.url}
                  />
                </div>
              )}
						</div>
					</div>
				</div>
      </div>
    </section>
  );
};

export default WhyUs;
