import * as React from "react";
import QuizLayout from "../layouts/Quiz";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Intro from "../components/Intro";
import AboutUs from "../components/AboutUs";
import HowItWorks from "../components/HowItWorks";
import InfoBox from "../components/InfoBox";
import WhyUs from "../components/WhyUs";
import GetInTouch from "../components/GetInTouch";
import aboutUsImage from "../assets/images/about-us-img.jpg";
import Video1 from "../assets/images/video-1.mp4";

const Index = () => {
  return (
		<>
			<Header />
			<Intro
				title="Cloud Application <span>Security Assessment</span>"
				subtitle="Improve Your Security Posture"
				text="The Cloud Application Security Assessment (CASA) aims to strengthen your application’s long-term security posture by assessing your cloud-connected application against a set of controls found in OWASP’s Application Verification Security Standard (ASVS) 4.0. Use this site to learn more about CASA and begin the self-assessment process."
				link={{
					url: "#section-about-us",
					label: "Learn more about CASA"
				}}
				badge="CASA"
			/>
			<AboutUs
				id="section-about-us"
				title="About CASA"
				subtitle="Why Do You Need CASA?"
				image={aboutUsImage}
				list={[
					{
						title: "Evaluate Security Posture",
						text: "Develop trust in your platform by evaluating your application’s security posture against a set of industry standards for application security."
					},
					{
						title: "Increase Consumer Confidence",
						text: "Show your customers that you respect their data by adhering to transparent security controls."
					},
					{
						title: "Drive Security Improvement",
						text: "Identify areas of your application’s security that need improvement."
					}
				]}
				link={{
					url: "#section-features",
					label: "Learn more about the assessment"
				}}
			/>
			<HowItWorks
				id="section-how-it-works"
				title="How it works"
				subtitle="Assess your app’s security without an in-house security team or a great deal of time. CASA has three levels for cloud services evaluation, making the process easy, fast, and efficient."
				list={[
					{
						title: "",
						subtitle: "Full Assessment by Leviathan",
						text: "Full assessments include the controls found in the base assessment, as well as additional controls to further evaluate your application. This assessment includes penetration testing your application in a production-like environment."
					},
					{
						title: "",
						subtitle: "Base Assessment",
						text: "As part of base assessments, Leviathan will test your application against the controls found in the self-assessment tier using a black-box testing methodology."
					},
					{
						title: "",
						subtitle: "Self-Assessment",
						text: "The Self-Assessment tier asks developers to attest to their compliance with a subset of ASVS controls through a questionnaire.",
						link: {
							url: "/quiz",
							label: "Get started now"
						}
					}
				]}
			/>
			<InfoBox
				id="section-features"
				title="OWASP Application Security Verification Standard"
				message="<sup>*</sup> To meet CASA requirements for self-assessment, controls marked with asterisk must be implemented and deployed within your platform:"
				list={[
					{
						title: "<span>v1</span> Architecture, Design and Thread Modeling Requirements",
						list: [
							{ text: "No deprecated client-side technologies" }
						]
					},
					{
						title: "<span>v2</span> Authentication Verification Requirements",
						list: [
							{ text: "Password at least 8 chars" },
							{ text: "Users can change password" },
							{ text: "Changing passwords require both current and new password" },
							{ text: "Anti-automation controls to limit brute force attacks" },
							{ text: "Secure notifications are sent after updates to authentication details" },
							{ text: "Shared or default accounts are not present" },
							{ text: "Forgotten password mechanism is time based" },
							{ text: "Out of band authentication requests are usable once" },
							{ text: "Out of band authenticator and verifier communicate over a secure independent channel" }
						]
					},
					{
						title: "<span>v3</span> Session Management Verification Requirements",
						list: [
							{ text: "Session tokens are not revealed in URL parameters" },
							{ text: "Application generates a new session token on user authentication" },
							{ text: "User logout invalidates session token" },
							{ text: "Cookie-based tokens have 'Secure' attribute set" },
							{ text: "Cookie-based tokens have 'HttpOnly' attribute set" },
							{ text: "User tokens shall be used rather than static API secrets and keys" },
							{ text: "Application gives the option to terminate all sessions after password change" },
							{ text: "Users are able to view and log out of any active sessions" },
							{ text: "Cookie-based tokens utilize 'Same Site' attribute" },
							{ text: "Cookie-based tokens use '__Host-' prefix" },
							{ text: "Cookie-based tokens shall use most precise path as possible" },
							{ text: "User may revoke OAuth tokens" },
							{ text: "If stateless tokens are used, then countermeasures must be implemented" },
							{ text: "Reauthentication before any sensitive transactions" }
						]
					},
					{
						title: "<span>v4</span> Access Control Verification Requirements",
						list: [
							{ text: "Access controls fail securely" },
							{ text: "Anti-CSRF mechanism to protect authenticated functionality" },
							{ text: "Enforce access control rules on a trusted service layer" },
							{ text: "User and data attributes and policy information used by access controls cannot be manipulated" },
							{ text: "Principle of least privilege exists" },
							{ text: "Sensitive data and APIs are protected against Insecure Direction Object Reference" },
							{ text: "Direct browsing is disabled, unless deliberately desired" }
						]
					},
					{
						title: "<span>v5</span> Validation, Sanitization and Encoding Verification Requirements",
						list: [
							{ text: "Defenses against HTTP parameter pollution attacks" },
							{ text: "Frameworks protect against mass parameter assignment attacks" },
							{ text: "URL redirects and forwards only allow destinations which appear on allow list" },
							{ text: "Application avoids use of eval()" },
							{ text: "Application protects against template injection attacks by sanitizing or sandboxing user data" },
							{ text: "Application protects against SSRF attacks by validating untrusted data" },
							{ text: "Application sanitizes or disables user-supplied SVG content" },
							{ text: "Application sanitizes or disables or user-supplied scriptable or expression template language content" },
							{ text: "Context-aware output escaping is implemented" },
							{ text: "Database queries use parametrized queries, ORMs, or other injection protections" },
							{ text: "Where parametrized mechanisms are not present, output encoding is used to protect against injection attacks" },
							{ text: "Application protects against JSON injection attacks" },
							{ text: "Application protects against OS command injection attacks" },
							{ text: "Application protects against Local (or Remote) file inclusion attacks" },
							{ text: "Application protects against XPath (or XML) injection attacks" },
							{ text: "Serialized objects use integrity checks" },
							{ text: "XML parsers are restricted to the most restrictive configuration possible" }
						]
					},
					{
						title: "<span>v6</span> Stored Cryptography Verification Requirements",
						list: [
							{ text: "Regulated private data is stored encrypted while at rest" },
							{ text: "Regulated health data is stored encrypted while at rest" },
							{ text: "Regulated financial data is stored encrypted while at rest" },
							{ text: "Proven or government approved cryptographic algorithms" }
						]
					},
					{
						title: "<span>v7</span> Error Handling and Logginng Verification Requirements",
						list: [
							{ text: "Security sensitive errors use generic messages" }
						]
					},
					{
						title: "<span>v8</span> Data Protection Verification Requirements",
						list: [
							{ text: "Users have a method to remove or export their data on demand" },
							{ text: "Anti-caching headers are set so sensitive data is not cached" },
							{ text: "Authenticated data is cleared from client storage after the session is terminated" },
							{ text: "Sensitive data is sent in the HTTP message body or headers" }
						]
					},
					{
						title: "<span>v9</span> Communications Verification Requirements",
						list: [
							{ text: "TLS shall be used for all client connectivity" },
							{ text: "Only strong ciphers shall be enabled and preferred in TLS session" },
							{ text: "TLS shall be 1.2 or newer" }
						]
					},
					{
						title: "<span>v10</span> Malicious Code Verification Requirements",
						list: [
							{ text: "Application does not ask for unnecessary or excessive privacy permissions" }
						]
					},
					{
						title: "<span>v12</span> File and Resources Verification Requirements",
						list: [
							{ text: "User-submitted filename metadata shall be validated to prevent path traversal" },
							{ text: "User-submitted filename metadata shall be validated to prevent execution" },
							{ text: "Application does not execute functionality from untrusted sources" },
							{ text: "Server configured to only serve files with specific extensions" },
							{ text: "Direct requests to upload files will never be executed as HTML/JavaScript content" }
						]
					},
					{
						title: "<span>v13</span> API and Web Service Verification Requirements",
						list: [
							{ text: "Requests containing unexpected content types are rejected" },
							{ text: "REST services check the incoming Content-Type to be the expected one" },
							{ text: "API URLs do not expose sensitive information" },
							{ text: "JSON schema validation is in place and verified before accepting inputs" },
							{ text: "Web services that utilize cookies are protected from Cross-Site Requests Forgery" },
							{ text: "XSD schema validation before processing user data" }
						]
					},
					{
						title: "<span>v14</span> Configuration Verification Requirements",
						list: [
							{ text: "Content Security Policy response header is in place" },
							{ text: "Web application cannot be embedded in a third-party site by default" },
							{ text: "Cross-Origin Resource Sharing (CORS) Access-Control-Allow-Origin header uses a strict allow list" },
							{ text: "All components are up to date" },
							{ text: "Debug modes are disabled" },
							{ text: "HTTP response contains a Content-Type header" },
							{ text: "API responses contain a Content-Disposition" },
							{ text: "Strict-Transport-Security header is included on all responses" },
							{ text: "Supplied Origin header is not used for authentication" }
						]
					}
				]}
				text="The CASA framework uses the OWASP Application Security Verification Standard (ASVS), considered a gold standard in platform security and privacy. Self-assessment is performed across 14 categories of the Application Security Verification Standard 4.0, covering each angle of the app's functioning. For implementation requirements and details of each item please visit <a href='https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md' target='_blank'>App Defense Alliance's Github page</a>"
				link={{
					url: "/quiz",
					label: "Get started with CASA"
				}}
			/>
			<WhyUs
				id="section-benefits"
				title="About us"
				subtitle="We Strive to Improve Security Confidence for Organizations Worldwide"
				text="Self-assessments require developers to complete a questionnaire"
				link={{
					url: "/quiz",
					label: "Get Started With the CASA Self-Assessment"
				}}
				list={[
					{
						title: "Official App Defence Alliance partner",
						text: "Leviathan is an official partner of App Defence Alliance, which focuses on the security of app ecosystems."
					},
					{
						title: "Industry leader",
						text: "Founded in 2006, Leviathan Security Group combines more than 16 years of experience with a drive for innovation."
					},
					{
						title: "Proven track record",
						text: "Leviathan's team consists of experienced professionals, guaranteeing our clients the highest quality of service."
					}
				]}
			/>
			<GetInTouch
				title="Contact us"
				subtitle="We are here to help"
				text="We are here to help with your CASA assessments and all your security needs. Visit <a href='https://www.leviathansecurity.com/' target='_blank' rel='noopener noreferrer'>Leviathan's website</a> for more information or contact us through email <a href='mailto:casa@leviathansecurity.com'>casa@leviathansecurity.com</a> if you have any questions."
				video={Video1}
			/>
			<Footer />
		</>
  )
}

export default Index;
