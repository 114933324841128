import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Logo from "../../assets/images/casa-logo.svg";
import "./Header.scss";

const Header: FunctionComponent = () => {
  const [isSticky, setIsSticky] = useState(false);

  const handleSticky = () => {
    setIsSticky(window.pageYOffset > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleSticky, {passive: true});
    return () => window.removeEventListener("scroll", handleSticky);
  }, []);

  return (
    <header className={classNames("header", isSticky && "header_sticky")}>
      <div className="container">
        <div className="header__inner">
          <Link className="header__logo" to="/">
            <img
              className="header__logo-image"
              src={Logo}
              alt="CASA by leviathan security group"
              width="141"
              height="26"
            />
          </Link>
          <ul className="header__nav">
            <li className="header__nav-item">
              <AnchorLink
                className="header__nav-link"
                href="#section-about-us"
                offset="100"
              >
                About CASA
              </AnchorLink>
            </li>
            <li className="header__nav-item">
              <AnchorLink
                className="header__nav-link"
                href="#section-how-it-works"
                offset="100"
              >
                How it works
              </AnchorLink>
            </li>
            <li className="header__nav-item">
              <AnchorLink
                className="header__nav-link"
                href="#section-features"
                offset="100"
              >
                OWASP ASVS
              </AnchorLink>
            </li>
            <li className="header__nav-item">
              <AnchorLink
                className="header__nav-link"
                href="#section-benefits"
                offset="100"
              >
                About us
              </AnchorLink>
            </li>
          </ul>
          <div className="header__aside">
            <Link
              className="header__button"
              to="/quiz"
            >
              Get started
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
