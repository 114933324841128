import React, { FunctionComponent } from "react";
import ButtonLink from "../ButtonLink";
import "./AboutUs.scss";

interface AboutUsProps {
  id?: string;
  title: string;
  subtitle: string;
  image: string;
  link: {
    url: string;
    label: string;
  };
  list: Array<{
    title: string;
    text: string;
  }>;
}

const AboutUs: FunctionComponent<AboutUsProps> = props => {
  const { id, title, subtitle, image, link, list } = props;

  return (
    <section className="about-us" id={id}>
      <div className="container">
        <div className="about-us__inner">
          {title && (
            <h2 className="about-us__title">{title}</h2>
          )}
          {subtitle && (
            <div className="about-us__subtitle">{subtitle}</div>
          )}
          <div className="about-us__body">
            {image && (
              <div className="about-us__image">
                <img
                  className="about-us__image-i"
                  src={image}
                  alt=""
                  width="569"
                  height="778"
                />
              </div>
            )}
            <div className="about-us__content">
              {list && (
                <ul className="about-us__list">
                  {list.map(item => (
                    <li className="about-us__list-item" key={item.title}>
                      {item.title && (
                        <div className="about-us__list-title">{item.title}</div>
                      )}
                      {item.text && (
                        <div className="about-us__list-text">{item.text}</div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {link && (
                <div className="about-us__footer">
                  <ButtonLink
                    title={link.label}
                    anchor={link.url}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
