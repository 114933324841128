import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./ButtonLink.scss";

interface ButtonLinkProps {
  title: string;
  url?: string;
  anchor?: string;
  className?: string;
}

const ButtonLink: FunctionComponent<ButtonLinkProps> = props => {
  const { title, url, anchor, className } = props;

  const icon = () => {
    return (
      <svg
        className="button-link__icon"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.45521 5.79156L5.65571 1.99206L6.65729 0.990479L12.1667 6.49989L6.65729 12.0093L5.65571 11.0077L9.45521 7.20823H0.833374V5.79156H9.45521Z"
          fill="black"
        />
      </svg>
    );
  };

  return (
    anchor ? (
      <AnchorLink
        className={classNames("button-link", className)}
        href={anchor}
        offset="100"
      >
        <span className="button-link__label">
          {title}
        </span>
        {icon()}
      </AnchorLink>
    ) : (
      <Link
        className={classNames("button-link", className)}
        to={url}
      >
        <span className="button-link__label">
          {title}
        </span>
        {icon()}
      </Link>
    )
  );
};

export default ButtonLink;
