import React, { FunctionComponent } from "react";
import ButtonLink from "../ButtonLink";
import "./HowItWorks.scss";

interface HowItWorksProps {
  id?: string;
  title: string;
  subtitle: string;
  list: Array<{
    title: string;
    subtitle: string;
    text: string;
    link?: {
      url: string;
      label: string;
    };
  }>;
}

const HowItWorks: FunctionComponent<HowItWorksProps> = props => {
  const { id, title, subtitle, list } = props;

  return (
    <section className="how-it-works" id={id}>
      <div className="container">
        {title && (
          <h2 className="how-it-works__title">{title}</h2>
        )}
        {subtitle && (
          <div className="how-it-works__subtitle">{subtitle}</div>
        )}
        {list && (
          <ul className="how-it-works__list">
            {list.map((item, index) => (
              <li className="how-it-works__list-item" key={`${item.title}-${index}`}>
                {item.title && (
                  <div className="how-it-works__list-title">{item.title}</div>
                )}
                {item.subtitle && (
                  <div className="how-it-works__list-subtitle">{item.subtitle}</div>
                )}
                {item.text && (
                  <div className="how-it-works__list-text">{item.text}</div>
                )}
                {item.link && (
                  <ButtonLink
                    className="how-it-works__list-button"
                    title={item.link.label}
                    url={item.link.url}
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default HowItWorks;
